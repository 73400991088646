import React, { useState } from "react"
import "../static/style/pageStyle/benefits.scss"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby";
import { benefitPageData } from "../services/helperFunctions";
import BannerHeader from "../components/bannerHeader";
import Underline from "../components/underline";
import BookDemo from "../components/book-demo";
import GetStartedModal from "../components/getStaredModal";

const Benefits = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
    const handleIsOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };
  const data = useStaticQuery(
    graphql`
      {
        allPryzmBenefits {
          nodes {
            pryzmBenefits {
              journey {
                attributes {
                  cmpName
                  heading
                  subheading
                  imageUrl
                  component {
                    textcardHeading
                    textcardParagraph
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const title = "The Benefits | PRYZM";
  const metsDescription="Enhance your team's decision-making process with PRYZM's powerful data management tools. Streamline operations & boost efficiency for better business outcomes.";
  const {
    banner,
    section1,
    section2,
    section3,
    section4,
    section5
  } = benefitPageData(data);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>{title}</title>
        <meta name="description" content={metsDescription} />
      </Helmet>
      <div className={`platform-v3 benefits`}>
        <div className="page-content">
          <div className="banner">
            <div className="page-wrapper">
              <div className="heading">
                <BannerHeader headingType={"bannerTitle"}
                  header={banner.heading}
                  line={"two-line"} />
              </div>
              <div className="paragraph">
                <p>{banner.subheading}</p>
              </div>
            </div>

          </div>


          {/* //dataops team  */}
          <div className="benefits-general">
            <div className="page-wrapper">
              <div className="image">
                <img src={`${process.env.STRAPI_URL + section1.imageUrl}`} />
              </div>
              <div className="content">
                <div className="head">
                  <h2>{section1.heading}</h2>
                  <p>{section1.subheading}</p>
                </div>
                <div className="benefits">
                  <h3>{section1.component.textcardHeading}</h3>
                  {section1.component.textcardParagraph.split('*').map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Underline />


          {/* data engineering team  */}
          <div className="benefits-general">
            <div className="page-wrapper data-eng">
              <div className="content">
                <div className="head">
                  <h2>{section2.heading}</h2>
                  <p>{section2.subheading}</p>
                </div>
                <div className="benefits">
                  <h3>{section2.component.textcardHeading}</h3>
                  {section2.component.textcardParagraph.split('*').map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              </div>
              <div className="image">
                <img src={`${process.env.STRAPI_URL + section2.imageUrl}`} />
              </div>
            </div>
          </div>
          <Underline />

          {/* data stewards  */}
          <div className="benefits-general">
            <div className="page-wrapper">
              <div className="image">
                <img src={`${process.env.STRAPI_URL + section3.imageUrl}`} />
              </div>
              <div className="content">
                <div className="head">
                  <h2>{section3.heading}</h2>
                  <p>{section3.subheading}</p>
                </div>
                <div className="benefits">
                  <h3>{section3.component.textcardHeading}</h3>
                  {section3.component.textcardParagraph.split('*').map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <Underline />

          {/* data consumer */}
          <div className="benefits-general">
            <div className="page-wrapper data-consumer">
              <div className="content">
                <div className="head">
                  <h2>{section4.heading}</h2>
                  <p>{section4.subheading}</p>
                </div>
                <div className="benefits">
                  <h3>{section4.component.textcardHeading}</h3>
                  {section4.component.textcardParagraph.split('*').map((item) => (
                    <p>{item}</p>
                  ))}
                </div>
              </div>
              <div className="image">
                <img src={`${process.env.STRAPI_URL + section4.imageUrl}`} />
              </div>
            </div>
          </div>
          {/* <Underline /> */}
          <div className='CTA'>
            <div className='page-wrapper'>
              <h2>{section5.heading}</h2>
              <p>{section5.subheading}</p>
              <div className="btn-area">
              <GetStartedModal content={"Contact Us"} isOpen={isOpen} setIsOpen={setIsOpen} isModalOpen={isModalOpen} handleIsOpenModal={handleIsOpenModal} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Benefits
